<template>
  <div class="cotainer" v-loading="loading">
    <el-breadcrumb class="p_tb_10" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item,index) in breadcrumb" :to="item.path" :key="index">{{
        item.name
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="m_tb_20 flex_justify_around box">
      <div class="pic">
        <img :src="contents.url?contents.url:contents.coverUrl" />
      </div>
      <div class="contents">
        <div class="title">{{contents.title}}</div>
        <div class="m_tb_5">
          <span class="fs_14 fw_bold">简介:</span>
          <span class="fs_14 content">
            {{contents.content}}
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    getDetail,
    getPartyDetail
  } from '@/api/party/index'
  export default {
    name: 'politicalLifeDetail',
    data: () => {
      return {
        contents: '',
        loading: false,
        breadcrumb:[]
      }
    },
    props: {
      type: {
        type: Number,
        default: 1 //1学校党建2模范先锋
      }
    },
    created() {
      let forms = parseInt(this.$route.query.from);
      this.getContent(forms);
      switch (forms) {
        case 1:
          this.breadcrumb = [{
            name: '学校党建',
            path: '/party/schoolParty/detail?cid=' + this.$route.query.pageId
          }, {
            name: '党员风采'
          }]
          break;
        case 2:
          this.breadcrumb = [{
            name: '模范先锋',
            path: '/party/exemplaryVanguard?id=' + this.$route.query.id
          }, {
            name: '优秀党员',
            path: '/party/exemplaryVanguard/partyList?id=' + this.$route.query.id + '&cid=' + this.$route.query
              .listId
          }, {
            name: '先进党员'
          }]
          break;
        case 3:
          this.breadcrumb = [{
            name: '模范先锋',
            path: '/party/exemplaryVanguard?id=' + this.$route.query.id
          }, {
            name: '先进党员'
          }]
          break;
      }
    },
    components: {

    },
    methods: {
      //获取内容详情
      async getContent(type) {
        if (type == 2||type == 3) {
          const {
            data
          } = await getDetail({
            id: this.$route.query.cid
          });
          this.contents = data;
        } else {
          const {
            data
          } = await getPartyDetail({
            id: this.$route.query.cid
          });
          this.contents = data;
        }
        this.loading = false;
      },
    }
  }
</script>
<style lang="scss" scoped>
  @font-face {
    font-family: PingFang-SC;
    src: url("../../../assets/fonts/苹方黑体-中粗-简.ttf");
  }

  .cotainer {

    .box {

      .pic {
        height: 320px;
        width: 300px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;

      }

      .contents {
        width: 100%;
        margin-left: 20px;
        color: #000;

        .title {
          font-size: 22px;
          opacity: 0.8;
          font-weight: 700;
          font-size: PingFang SC;
        }

        .content {
          font-size: 14px;
          line-height: 22px;
          font-size: PingFang SC;
          font-weight: 500;
          opacity: 0.6;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }



  }
</style>
